import React, { useEffect, useState } from "react"
import { Button } from "@mui/material"
import "./style.scss"

function SegmentedControl({ buttonStyle, onChange, options, value, label }) {
  const [selectorStyle, setSelectorStyle] = useState({})
  const [windowWidth, setWindowWidth] = useState({})
  const buttonRefs = []
  options.map(() => buttonRefs.push(React.createRef()))

  useEffect(() => {
    const index = options.findIndex(option => option.value === value)
    if (index !== -1) {
      const button = buttonRefs[index].current
      const width = Math.round(button.getBoundingClientRect().width) + 1
      let left =
        Math.round(
          button.getBoundingClientRect().x -
            button.parentNode.getBoundingClientRect().x
        )
        left = left === 2? -1:left
      setSelectorStyle({
        width,
        left,
      })
    }
  }, [value, windowWidth])// eslint-disable-line

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return (
    <div className="segmented-control-container">
      <div className="segmented-control-label">{label}</div>
      <div className="segmented-control">
        {options.map((opt, index) => (
          <Button
            className={`${opt.value === value ? "active" : ""} `}
            disableRipple
            key={opt.value}
            ref={buttonRefs[index]}
            fullWidth
            onClick={() => onChange(opt.value)}
            style={buttonStyle}
          >
            {opt.label}
          </Button>
        ))}
        {value && (
          <div
            className={`segmented-control__selector ${
              value === 1 ? "left" : "right"
            }`}
            style={selectorStyle}
          />
        )}
      </div>
    </div>
  )
}
export default SegmentedControl
