import React, { useState } from "react"
import Layout from "../containers/Layout"
import { graphql, navigate  } from "gatsby"
import { useTranslation } from "react-i18next"
import TextField from "@mui/material/TextField/TextField"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete"
import useFormErrors from "../hooks/useFormErrors"
import Button from "../components/genericComponents/Button"
import SegmentedControl from "../components/genericComponents/SegmentedControl"
import NumberFormat from "react-number-format"
import { InputAdornment } from "@mui/material"
import SEO from "../components/genericComponents/SEO"
const locations = [
  { label: "calculator_field_location_option_acoruna", iva:0.1, itp:0.1, ajd:0.015 },
  { label: "calculator_field_location_option_alava", iva:0.1, itp:0.04, ajd:0 },
  { label: "calculator_field_location_option_albacete", iva:0.1, itp:0.09, ajd:0.015 },
  { label: "calculator_field_location_option_alicante", iva: 0.1, itp:0.1, ajd:0.01},
  { label: "calculator_field_location_option_almeria", iva:0.1, itp:0.07, ajd:0.012 },
  { label: "calculator_field_location_option_asturias", iva:0.1, itp:0.08, ajd:0.012 },
  { label: "calculator_field_location_option_avila", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_badajoz", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_baleares", iva:0.1, itp:0.08, ajd:0.012 },
  { label: "calculator_field_location_option_barcelona", iva:0.1, itp:0.1,ajd:0.015 },
  { label: "calculator_field_location_option_burgos", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_caceres", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_cadiz", iva:0.1, itp:0.07, ajd:0.012 },
  { label: "calculator_field_location_option_cantabria", iva:0.1, itp:0.1, ajd:0.015 },
  { label: "calculator_field_location_option_castellon", iva: 0.1, itp:0.1, ajd:0.01 },
  { label: "calculator_field_location_option_ceuta", iva: 0.1, itp:0.06, ajd:0.005 },
  { label: "calculator_field_location_option_ciudad_real", iva:0.1, itp:0.09, ajd:0.015 },
  { label: "calculator_field_location_option_cordoba", iva:0.1, itp:0.07, ajd:0.012 },
  { label: "calculator_field_location_option_cuenca", iva:0.1, itp:0.09, ajd:0.015 },
  { label: "calculator_field_location_option_girona", iva:0.1, itp:0.1,ajd:0.015 },
  { label: "calculator_field_location_option_granada", iva:0.1, itp:0.07, ajd:0.012 },
  { label: "calculator_field_location_option_guadalajara", iva:0.1, itp:0.09, ajd:0.015 },
  { label: "calculator_field_location_option_gipuzkoa", iva:0.1, itp:0.04, ajd:0 },
  { label: "calculator_field_location_option_huelva", iva:0.1, itp:0.07, ajd:0.012 },
  { label: "calculator_field_location_option_huesca", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_jaen", iva:0.1, itp:0.07, ajd:0.012 },
  { label: "calculator_field_location_option_la_rioja", iva:0.1, itp:0.07, ajd:0.01 },
  { label: "calculator_field_location_option_las_palmas", iva:0.065, itp:0.065, ajd:0.04 },
  { label: "calculator_field_location_option_leon", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_lerida", iva:0.1, itp:0.1,ajd:0.015 },
  { label: "calculator_field_location_option_lugo", iva:0.1, itp:0.1, ajd:0.015 },
  { label: "calculator_field_location_option_madrid", iva:0.1, itp:0.06, ajd:0.007 },
  { label: "calculator_field_location_option_malaga", iva:0.1, itp:0.07, ajd:0.012 },
  { label: "calculator_field_location_option_melilla", iva:0.1, itp:0.06, ajd:0.005 },
  { label: "calculator_field_location_option_murcia", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_navarra", iva:0.1, itp:0.06 ,ajd:0.05 },
  { label: "calculator_field_location_option_ourense", iva:0.1, itp:0.1, ajd:0.015 },
  { label: "calculator_field_location_option_palencia", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_pontevedra", iva:0.1, itp:0.1, ajd:0.015 },
  { label: "calculator_field_location_option_salamanca", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_segovia", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_sevilla", iva:0.1, itp:0.07, ajd:0.012 },
  { label: "calculator_field_location_option_soria", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_tarragona", iva:0.1, itp:0.1,ajd:0.015 },
  { label: "calculator_field_location_option_santa_cruz_de_tenerife", iva:0.065, itp:0.065, ajd:0.04 },
  { label: "calculator_field_location_option_teruel", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_toledo", iva:0.1, itp:0.09, ajd:0.015 },
  { label: "calculator_field_location_option_valencia", iva: 0.1, itp:0.1, ajd:0.01 },
  { label: "calculator_field_location_option_valladolid", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_vizcaya", iva:0.1, itp:0.04, ajd:0 },
  { label: "calculator_field_location_option_zamora", iva:0.1, itp:0.08, ajd:0.015 },
  { label: "calculator_field_location_option_zaragoza", iva:0.1, itp:0.08, ajd:0.015 },
]

const CalculatorPage = props => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const formatter = new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 0
	});
  const [data, setData] = useState({
    itp:'',
    notaria:1300,
    gestoria:600,
    registro:800,
    tax:600,
    total:2500
  })
  const validations = ["price", "location"]
  const { form, errors, setInForm, setError } = useFormErrors(
    { price: '', location: "Barcelona", isNew: 1 },
    validations
  )
  

  function calcData(name, value){
    let total = data.notaria + data.gestoria + data.registro;
    let price = form.price;    
    let location = form.location;
    let isNew = form.isNew;
    let itp = '';
    let iva = '';
    let iajd = '';

    if(!form.price){
      setError({price: t('error_form_obligatory_field')})
      return 
    }else if(form.price < 50000){
      setError({price: t('error_form_field_upper_50000')})
      return 
    }else if(form.price > 1500000){
      setError({price: t('error_form_field_upper_1500000')})
      return 
    }

    
    if(location){
      let locationdata = locations.filter(x => t(x.label) === location)[0]
      if(isNew === 1){
        iva = price*locationdata.iva;
        iajd = price*locationdata.ajd;
        total = total + iva + iajd
      }else{
        itp = (price*(locationdata.itp))
        total = total + itp
      }
    }

    setData({
      notaria:1300,
      gestoria:600,
      registro:800,
      tax:600,
      total: total,
      itp:itp,
      iva:iva,
      iajd:iajd,
      textIvaIgic: (form.location === t('calculator_field_location_option_las_palmas') || form.location === t('calculator_field_location_option_santa_cruz_de_tenerife')) ? t('calculator_result_igic') : t('calculator_result_iva'),
      textAjdItp: (form.isNew === 1) ? t('calculator_result_ajd') : t('calculator_result_itp'),
    })
    setShowDetails(true)
    if(typeof window !== "undefined" && window && window.innerWidth < 767){
      navigate('#results', {offset:500})
    }
    

  }

  return (
    <Layout
      navBarProps={{
        navClass: "is--white",
      }}
      logoClassName={"filterClass"}
      path={props.path}
      optionsBooking={{ path: props.path }}
    >
      <SEO
        title={t('seo_calculator_title')}
        desc={t('seo_calculator_description')}
        pathname={"/calculadora/"}
        article={false}
        section={t('seo_calculator_section')}
        tags={{
          tag_1:t('seo_calculator_tag1'),
          tag_2:t('seo_calculator_tag2'),
          tag_3:t('seo_calculator_tag3'),
          tag_4:t('seo_calculator_tag4'),
        }}
      />
      <section className="calculator-container" data-scroll-section>
        <div className="calculator container-generic">
          <h1>{t("calculator_title")}</h1>
          <p className="calculator_subtitle">{t("calculator_subtitle")}</p>
          <div className="calculator__content">
            <div className="calculator__content-form">
              {createTextField(
                t("calculator_field_price_label"),
                t("calculator_field_price_placeholder"),
                "price",
                true
              )}
              {createAutocomplete(
                t("calculator_field_location_label"),
                t("calculator_field_location_placeholder"),
                "location",
                true,
                locations.map(x => t(x.label))
              )}
              <SegmentedControl
                label={t("calculator_field_type_house")}
                buttonStyle={{ minWidth: 138 }}
                options={[
                  {
                    label: t("calculator_field_type_house_value_new"),
                    value: 1,
                  },
                  {
                    label: t("calculator_field_type_house_value_old"),
                    value: 2,
                  },
                ]}
                value={form.isNew}
                onChange={value => {
                  setInForm("isNew", value)
                }}
              />
              <div>
                <Button
                  buttonClass={"outlined"}
                  label={t("calculator_btn_update")}
                  onClick={() => calcData()}
                  disabled={!form.price || form.price === '' || form.price === 0}
                />
              </div>
            </div>
            <div id="results" className="calculator__content-middle"></div>
            <div  className="calculator__content-results">
              <div className="calculator__content-results-title">
                {t("calculator_result_title")}
              </div>
              <div className="calculator__content-results-price">
                {showDetails?formatter.format(data.total):'0€'}
              </div>
              <p className="calculator__content-results-info">
                {t("calculator_result_subtitle")}
              </p>
             {showDetails && <div className="calculator__content-results-details">
                <div className={`calculator__content-results-details-header ${open?'open':''}`} {...buttonize(() => setOpen(!open))}>
                  <div className="calculator__content-results-details-header-label">{t('calculator_result_view_details')}</div>
                </div>
                <div className={`calculator__content-results-details-datavalues  ${open?'open':''}`}>
                 {data.itp !== '' && <div className="calculator__content-results-details-datavalues-data">
                    <div className="calculator__content-results-details-datavalues-data-label">{data.textAjdItp}</div>
                    <div className="calculator__content-results-details-datavalues-data-value">{data.itp?formatter.format(data.itp):'0€'}</div>
                  </div>}
                  {data.iva !== '' && <div className="calculator__content-results-details-datavalues-data">
                    <div className="calculator__content-results-details-datavalues-data-label">{data.textIvaIgic}</div>
                    <div className="calculator__content-results-details-datavalues-data-value">{data.iva?formatter.format(data.iva):'0€'}</div>
                  </div>}
                  {data.iajd !== '' && <div className="calculator__content-results-details-datavalues-data">
                    <div className="calculator__content-results-details-datavalues-data-label">{t('calculator_result_ajd')}</div>
                    <div className="calculator__content-results-details-datavalues-data-value">{data.iajd?formatter.format(data.iajd):'0€'}</div>
                  </div>}
                  <div className="calculator__content-results-details-datavalues-data">
                    <div className="calculator__content-results-details-datavalues-data-label">{t('calculator_result_notary')}</div>
                    <div className="calculator__content-results-details-datavalues-data-value">{data.notaria?formatter.format(data.notaria):''}</div>
                  </div>
                  <div className="calculator__content-results-details-datavalues-data">
                    <div className="calculator__content-results-details-datavalues-data-label">{t('calculator_result_gestoria')}</div>
                    <div className="calculator__content-results-details-datavalues-data-value">{data.gestoria?formatter.format(data.gestoria):''}</div>
                  </div>
                  <div className="calculator__content-results-details-datavalues-data">
                    <div className="calculator__content-results-details-datavalues-data-label">{t('calculator_result_register')}</div>
                    <div className="calculator__content-results-details-datavalues-data-value">{data.registro?formatter.format(data.registro):''}</div>
                  </div>
                  <div className="calculator__content-results-details-datavalues-data">
                    <div className="calculator__content-results-details-datavalues-data-label">{t('calculator_result_tax')}</div>
                    <div className="calculator__content-results-details-datavalues-data-value">{data.tax?formatter.format(data.tax):''}</div>
                  </div>
                </div>
              </div>}
              <div>
              {showDetails && <Button buttonClass={'contained'} label={t('button_register_now')} onClick={() => (window.location.href = "https://app.dardanius.com/registro")}></Button>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )

  function buttonize(handlerFn) {
    return {
      role: 'button',
      onClick: handlerFn,
      onKeyDown: event => {
        if (event.keycode === 13) handlerFn(event);
      }
    }
  }

  function createAutocomplete(
    label,
    placeholder,
    name,
    required,
    options,
    propsComponent = {}
  ) {
    return (
      <div className="field-content">
        <div className="field-content-label">{label}</div>
        <Autocomplete
          id={name}
          name={name}
          options={options}
          value={form[name]}
          required={required}
          onChange={(event, value) => {
            setInForm(name, value)
          }}
          disableClearable
          {...propsComponent}
          renderInput={params => (
            <TextField
              className="custom-fields"
              {...params}
              variant={"filled"}
              placeholder={placeholder}
              fullWidth
              InputLabelProps={{ shrink: true }}
              required={required}
              error={Boolean(errors[name])}
              helperText={errors[name]}
            />
          )}
        />
      </div>
    )
  }

  function createTextField(
    label,
    placeholder,
    name,
    required,
    propsTextField = {}
  ) {
    return (
      <div className="field-content">
        <div className="field-content-label">{label}</div>
        <TextField
          className="custom-fields single-input"
          error={Boolean(errors[name])}
          helperText={errors[name]}
          required={required}
          id={name}
          name={name}
          value={form[name]}
          placeholder={placeholder}
          onChange={event => {
            setInForm(name, event.target.value)
          }}
          variant="filled"
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{maxLength: 9}}
          InputProps={{ inputComponent: NumberFormatCustom, endAdornment: <InputAdornment position="end">€</InputAdornment> }}
          {...propsTextField}
        />
      </div>
    )
  }


}

export default CalculatorPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
const NumberFormatCustom = React.forwardRef((props, ref) =>{
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString={true}
      decimalScale={2}
      suffix={'€'}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
    />
  )
})